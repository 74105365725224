import React, { useRef } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

import { FiUnlock, FiCheckCircle, FiUserCheck, FiSmartphone, FiShoppingBag } from 'react-icons/fi'

import { MainTextHolder } from './Utils'

export default function How() {
  const controls = useAnimation()
  const { ref, inView } = useInView();
  const animated = useRef<boolean>(false)

  useEffect(() => {
    if (inView && !animated.current) {
      controls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.3, duration: 0.8 },
      }))
      animated.current = true
    } else {
      controls.start({
        opacity: 1,
        y: 0,
        transition: {
          delay: 0,
          duration: 0
        }
      })
    }
  }, [controls, inView])

  return (
    <div id="work">
      <MainTextHolder>
        <h3>Så här fungerar det för din kund</h3>
        <HowItemHolder ref={ref}>
          <HowItem
            custom={0}
            animate={controls}
            initial={{ opacity: 0, y: 20 }}
            key={uuid()}
          >
            <ListItem
              title="Ladda ner vår app"
              text="24Food appen finns på Google Play och App Store."
              icon={<FiSmartphone size="80px" />}
            />
          </HowItem>
          <HowItem
            custom={1.75}
            animate={controls}
            initial={{ opacity: 0, y: 20 }}
            key={uuid()}
          >
            <ListItem
              title="Skapa konto"
              text="Logga in med bankID och skicka in bild på en giltig ID-handling."
              icon={<FiUnlock size="80px" />}
            />
          </HowItem>
          <HowItem
            custom={3.5}
            animate={controls}
            initial={{ opacity: 0, y: 20 }}
            key={uuid()}
          >
            <ListItem
              title="Handla"
              text="Välj dina produkter från vårt sortiment och lägg till dem i varukorgen."
              icon={<FiShoppingBag size="80px" />}
            />
          </HowItem>
          <HowItem
            custom={5.25}
            animate={controls}
            initial={{ opacity: 0, y: 20 }}
            key={uuid()}
          >
            <ListItem
              title="Ålderskontroll"
              text="Kolla in i kameran och vänta 6-10 sekunder."
              icon={<FiUserCheck size="80px" />}
            />
          </HowItem>
          <HowItem
            custom={5.25}
            animate={controls}
            initial={{ opacity: 0, y: 20 }}
            key={uuid()}
          >
            <ListItem
              title="Betala"
              text="Ta din vara och betala i kassan, om du inte redan har betalat i appen."
              icon={<FiCheckCircle size="80px" />}
            />
          </HowItem>
        </HowItemHolder>
      </MainTextHolder>
    </div>
  )
}

const ListItem = ({
  title,
  text,
  icon,
}: {
  title: string;
  text: string;
  icon: React.ReactNode;
}) => {
  return (
    <ItemHolder>
      {icon}
      <div>
        <strong>{title}</strong>
        <p>{text}</p>
      </div>
    </ItemHolder>
  )
}

const HowItemHolder = styled(motion.ul)`
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
`

const HowItem = styled(motion.li)`
  font-family: Glasgow;
  font-weight: 500;
  color: #fff;
  text-align: center;
  opacity: 0;
  text-align: left;
  width: 100%;

  @media (min-width: 769px) {
    width: 50%;

    &:nth-child(even) {
      width: calc(50% - 20px);
      margin-left: 20px;
    }
  }
`

const ItemHolder = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 40px;

  svg {
    margin-right: 20px;

    @media (min-width: 769px) {
      margin-right: 40px;
    }
  }

  strong {
    font-size: 22px;
    line-height: 28px;
    font-family: Glasgow;
    font-weight: 500;

    @media (min-width: 769px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    margin: 8px 0;

    @media (min-width: 769px) {
      font-size: 22px;
      line-height: 28px;
    }
  }

  > div {
    width: 100%;
  }
`
