import React from 'react'
import styled from 'styled-components'

import lannaStore from '../img/lanna.jpeg'

import { MainTextHolder } from './Utils'

export default function About() {
  return (
    <AboutHolder id="about">
      <StyledMainTextHolder>
        <h3>Om oss</h3>
        <p>
          Sverige är ett av världens mest tobaks konsumerande länder. Tidigt i vårt syster bolags 24Food:s resa märkte vi att Tobak var en oerhört efterfrågad varugrupp. Frågan var hur vi kunde göra det tillgängligt? 
          <br />
          <br />
          Genom realtidsverifiering kan vi erbjuda en unik digital lösning för obemannad tobakshandel. Vi är en del av 24Food, och vårt mål är att finnas där vi behövs. Tobaksautomater kan finnas på golfbanor, arbetsplatser, obemannade matbutiker, campingplatser – överallt där behovet finns!
          <br />
          <br />
          Vi är övertygade om att vår produkt kan förenkla människors vardag och samtidigt bidra till en ökad försäljning. Försäljningen i våra tobaksautomater har visat sig skapa en fantastisk samhällsservice samtidigt som den har bidragit till en merförsäljning av andra produkter med flera procent.
          <br />
          <br />
          Om du vill samarbeta med oss eller höra mer om vad vi kan erbjuda är du välkommen att kontakta oss på info@24food.se. Vi ser fram emot att höra från dig!
        </p>  
      </StyledMainTextHolder>
    </AboutHolder>
  )
}

const AboutHolder = styled.div`
  // background-image: url(${lannaStore});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;

  // &:before {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background: rgba(0, 0, 0, 0.7);
  //   z-index: 3;
  // }
`

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  z-index: 4;
  margin: 0 auto;

  p {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
    text-shadow: 1px 1px 4px black;
  }
`
