import React from 'react'
import styled from 'styled-components'

import lannaStore from '../img/lanna.jpeg'

import { MainTextHolder } from './Utils'

export default function Solution() {
  return (
    <AboutHolder id="solution">
      <StyledMainTextHolder>
        <h3>Hur är det möjligt?</h3>
        <p>
          Genom realtids verifieringen möjliggör vi att kunden på ett smidigt och unikt sätt kan köpa sin tobak.
        </p>  
      </StyledMainTextHolder>
    </AboutHolder>
  )
}

const AboutHolder = styled.div`
  background-image: url(${lannaStore});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 3;
  }
`

const StyledMainTextHolder = styled(MainTextHolder)`
  position: relative;
  z-index: 4;
  margin: 0 auto;

  p {
    font-size: 22px;
    line-height: 28px;
    text-align: left;
    text-shadow: 1px 1px 4px black;
    text-align: center;
  }
`
