import React from 'react'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import { v4 as uuid } from 'uuid'

import { scrollToDiv } from './Utils'

interface MenuItem {
  title: string;
  link: string;
  children: MenuItem[];
};

const menuItems: MenuItem[] = [
  { title: 'Så fungerar det', link: 'work', children: [] },
  { title: 'Våran lösning', link: 'solution', children: [] },
  { title: 'Om oss', link: 'about', children: [] },
]

export default class MenuItems extends React.Component {
  render() {
    return (
      <ul>
        {menuItems.map(page => {
          return (
            <React.Fragment key={uuid()}>
              <StyledLi key={uuid()}>
                <button
                  onClick={() => {
                    scrollToDiv(page.link)
                  }}
                >
                  {page.title}
                </button>
              </StyledLi>
              {page.children.map(page => (
                <MediaQuery maxDeviceWidth={768} key={uuid()}>
                  <StyledSubLi>
                    <button onClick={() => scrollToDiv(page.link)}>
                      {page.title}
                    </button>
                  </StyledSubLi>
                </MediaQuery>
              ))}
            </React.Fragment>
          )
        })}
      </ul>
    )
  }
}

const StyledLi = styled.li`
  margin-right: 16px;
  margin-bottom: 16px;
  white-space: nowrap;

  button {
    font-family: Glasgow;
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.8px;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;

    @media (min-width: 769px) {
      font-size: 20px;
    }
  }

  @media (min-width: 769px) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const StyledSubLi = styled(StyledLi)`
  margin-left: 20px;
  margin-bottom: 8px;

  button {
    text-transform: none;
  }

  :first-child {
    margin-top: -10px;
  }
`
