import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

type Props = {
  children: React.ReactNode;
  className?: React.CSSProperties;
}

export const MainTextHolder = ({ children, className }: Props) => {
  return <MainText className={className}>{children}</MainText>
}

const MainText = styled.div`
  text-align: center;
  padding: 40px;
  box-sizing: border-box;

  @media (min-width: 769px) {
    width: 980px;
    max-width: 100%;
    margin: 0px auto;
    padding: 60px;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 4px;
    font-size: 17px;
  }

  h3 {
    font-size: 32px;
    line-height: 40px;
    color: #fff;

    @media (min-width: 769px) {
      font-size: 42px;
      line-height: 50px;
    }
  }

  > h4 {
    margin-top: 0;
  }

  figure {
    width: 100%;
    margin: 0;

    > img {
      width: 100%;
    }
  }
`

export const MainHolder = styled(motion.div)`
  width: 100vw;
  margin-top: 60px;

  h4 {
    text-transform: uppercase;
  }

  h3 {
    font-size: 60px;
    line-height: 68px;
    color: #fff;
  }

  strong {
    font-family: Glasgow;
    font-weight: 700;
  }

  p {
    margin-bottom: 20px;
    margin-top: 0;
  }

  @media (min-width: 769px) {
    max-width: 980px;
    margin: 0 auto;
  }
`

export const StyledImage = styled.img`
  width: 100%;
`

export const Spacer = styled.div`
  width: 100%;
`

export const getSlug = (link: string) => {
  if (link.substr(link.length - 1) === '/') {
    link = link.substr(0, link.length - 1)
  }
  link = link.substr(link.lastIndexOf('/'))

  return link
}

export const Loading = () => {
  return (
    <Spinner>
      <div className="rect1"></div>
      <div className="rect2"></div>
      <div className="rect3"></div>
      <div className="rect4"></div>
      <div className="rect5"></div>
    </Spinner>
  )
}

const Spinner = styled.div`
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

  > div {
    background-color: #aaa;
    height: 100%;
    width: 6px;
    display: inline-block;
    margin: 2px;

    animation: sk-stretchdelay 1.2s infinite ease-in-out;

    &.rect2 {
      animation-delay: -1.1s;
    }

    &.rect3 {
      animation-delay: -1s;
    }

    &.rect4 {
      animation-delay: -0.9s;
    }

    &.rect5 {
      animation-delay: -0.8s;
    }
  }

  @keyframes sk-stretchdelay {
    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.6);
    }
    20% {
      transform: scaleY(1);
      -webkit-transform: scaleY(1);
    }
  }
`

export const scrollToDiv = (pageLink: string, offset?: number) => {
  let element = document.getElementById(pageLink) || document.body
  let headerOffset = offset || 80
  let elementPosition = element.offsetTop
  let offsetPosition = elementPosition - headerOffset

  window.scrollTo({
    top: offsetPosition,
    left: 0,
    behavior: 'smooth',
  })
}
