import React from 'react'

import Header from './components/Header'
import Footer from './components/Footer'
import Hero from './components/Hero'
import How from './components/How'
import About from './components/About'
import Solution from './components/Solution'

export default class App extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Hero />
        <How />
        <Solution />
        <About />
        <Footer />
      </>
    )
  }
}
